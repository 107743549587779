import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import queryString from "query-string"
// import Img from "gatsby-image"
// import { _localizedSlug } from "../core/utils"
// import { Layout } from "../components/Layout"
import ProductsUI from "../components/modules/ProductsUI"
import { slugify } from "../core/utils"
import { Helmet } from "react-helmet"

export const query = graphql`
  query SearchPagesFr {
    allSanityProduct {
      nodes {
        id
        ...productCard
      }
    }
  }
`

class SearchPage extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
  }

  constructor(props) {
    super(props)

    const { data, location } = this.props
    const pagePaths = data.allSanityProduct.nodes.map(node => node.slug.current)
    const urlState = queryString.parse(location.search)
    // console.log(pagePaths)
    const initialSearchTerms = urlState.s ? slugify(urlState.s) : ``
    const filteredPaths = this.getFilteredPagePaths(
      pagePaths,
      initialSearchTerms
    )
    // console.log("initialSearchTerms", initialSearchTerms)
    // console.log("filteredPaths", filteredPaths)

    const result = data.allSanityProduct.nodes.filter(el => {
      // console.log(filteredPaths, el.title.toLowerCase())
      // const idSearch = filteredPaths.includes(el.id);``
      const title = slugify(el.title.toLowerCase())
      // const exist = filteredPaths.filter(path => {
      //   console.log(
      //     path,
      //     title,
      //     path.search(`/${title}/i`)
      //     // path.indexOf(title),
      //     // path.includes(title)
      //   )
      //   return path.indexOf(title) > -1
      //   // return path.includes(title)
      // })
      const exist = title.includes(initialSearchTerms)
      // console.log("exist", exist)
      return exist
    })
    // console.log(result)
    this.state = {
      initPagePaths: pagePaths,
      searchTerms: initialSearchTerms,
      pagePaths: filteredPaths,
      result: result,
    }
  }

  componentDidMount() {
    // console.log(this.state);
  }

  getFilteredPagePaths(allPagePaths, searchTerms) {
    const searchTerm = new RegExp(`${searchTerms}`, "i")
    return allPagePaths.filter(pagePath => searchTerm.test(pagePath))
  }

  render() {
    // const lang = {
    //   lang: "fr-fr",
    // }
    // console.log(this.state.result)
    return (
      <div className="main-area">
        <Helmet title={"Search"}>
          <body className={`page-search snipcart-overwrite`} />
        </Helmet>
        <div className="search bg-secondary">
          <ProductsUI
            input={{ showFilters: false, products: this.state.result }}
          />
          {}
        </div>
      </div>
    )
  }
}

export default SearchPage

// import React from "react"
// import PropTypes from "prop-types"
// import { graphql } from "gatsby"
// import queryString from "query-string"
// // import Img from "gatsby-image"
// // import { _localizedSlug } from "../core/utils"
// // import { Layout } from "../components/Layout"
// import ProductsUI from "../components/modules/ProductsUI"

// export const query = graphql`
//   query SearchPagesFr {
//     allSanityProduct {
//       nodes {
//         id
//         ...productCard
//       }
//     }
//   }
// `

// class SearchPage extends React.Component {
//   static propTypes = {
//     data: PropTypes.object,
//     location: PropTypes.object,
//   }

//   constructor(props) {
//     super(props)

//     const { data, location } = this.props

//     const pagePaths = data.allSanityProduct.nodes.map(node => {
//       return {
//         slug: node.slug.current,
//         title: node.title.toLowerCase(),
//       }
//     })

//     const urlState = queryString.parse(location.search)
//     const searchTerms = urlState.s ? urlState.s.replace("'", " ") : ``
//     console.log("searchTerms: ", searchTerms)
//     // console.log("filteredPaths: ", filteredPaths)

//     const result = data.allSanityProduct.nodes.filter(el => {
//       const title = el.title.toLowerCase().replace("’", " ")
//       const slug = el.slug.current

//       const exist =
//         title.includes() ||
//         title.indexOf(searchTerms) > -1 ||
//         slug.includes(searchTerms)

//       // console.log(
//       //   searchTerms,
//       //   title,
//       //   title.search(searchTerms),
//       //   title.includes(searchTerms),
//       //   title.indexOf(searchTerms)

//       // )
//       console.log("exist", exist)
//       return exist
//     })
//     this.state = {
//       searchTerms: searchTerms,
//       result: result,
//     }
//   }

//   componentDidMount() {
//     // console.log(this.state);
//   }

//   getFilteredPagePaths(allPagePaths, searchTerms) {
//     const searchTerm = new RegExp(`${searchTerms}`, "i")
//     return allPagePaths.filter(pagePath => {
//       const exist =
//         searchTerm.test(pagePath.slug) || searchTerm.test(pagePath.title)
//     })
//   }

//   render() {
//     // const lang = {
//     //   lang: "fr-fr",
//     // }
//     console.log(this.state.result)
//     return (
//       <div className="search ">
//         <ProductsUI
//           input={{ showFilters: false, products: this.state.result }}
//         />
//       </div>
//     )
//   }
// }

// export default SearchPage
